// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

body{
    background-color: #fff;
}
.app-genpassword{
  margin-top: 150px !important;
}
@import 'ngx-toastr/toastr';

#toast-container > div {
    opacity: 1;
  }

  .main .container-fluid {
    padding: 0 15px !important;
}

.card{
  margin-bottom: 0px  !important;
}

.table th, .table td {
  padding: 6px !important;
}

.bg-plum{
  background-color: #8a335c !important;
}

.btn-plum{
  background-color: #8a335c !important;
  color: white !important;
}

.brand-logo{
  width: 100% !important;
}
.nopadding{padding-left: 0px;padding-right: 0px;}
.logintype{
  border: 1px solid #e2e2e2;
    text-align: center;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.activeTab{
  background-color: #8a335c;
  color: #fff;
}

.auth-head{
  text-align: center;
    font-size: 18px;
    background: #8a335c;
    padding: 10px;
    font-weight: bold;
    color: #fff;
}